<template>
   <div class="content">
         <div class="main">
            <div class="main-r">
                <div class="div1">
                        需求列表
                </div>

                <div class="div2">
                    <div class="div2-1">
                        需求号
                    </div>
                    <div class="div2-2">
                        需求名称
                    </div>
                    <div class="div2-3">
                        金额
                    </div>
                    <div class="div2-4">
                        
                        发布时间
                    </div>
                    <div class="div2-5">
                        截止时间
                    </div>
                     <div class="status">
                       <el-dropdown  placement="bottom-start" @command="handleCommand" trigger="click">
                        <span class="el-dropdown-link" >
                            {{info2}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown" placement="start">
                            <el-dropdown-item command="审核中">审核中</el-dropdown-item>
                            <el-dropdown-item command="审核未通过" >审核未通过</el-dropdown-item>
                            <el-dropdown-item command="招标中">招标中</el-dropdown-item>
                            <el-dropdown-item command="已完成">已完成</el-dropdown-item>
                            <el-dropdown-item command="已关闭">已关闭</el-dropdown-item>
                            <el-dropdown-item command="全部状态">全部状态</el-dropdown-item>
                        </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>



                <ul class="ulList">
                     <li v-for="(item,i) in list" :key="i">
                        <div class="li1">{{item.inviteId}}</div>
                        <router-link :to="'/quoteMerchant/'+item.inviteId" tag="div">
                            <div class="li2">
                                <a>
                                     {{item.requirementDesc}}
                                </a>                               
                            </div>
                         </router-link>
                        <div class="li3">￥{{item.budgetAmount}}</div>
                        <div class="li4">                           
                            <div>{{item.createDate}}</div>
                        </div>
                        <div class="li5">
                            <div>{{item.endDate}}</div>
                        </div>
                        <div class="li6">{{item.state | fil1}}</div>
                    </li>
                </ul>



                <div class="pagination">
                        <div class="block">
                            <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="[2,4,8]"
                            :page-size="8"
                            layout="total, sizes, prev, pager, next, jumper"
                             v-bind:total="totalSize"
                            >
                            </el-pagination>
                        </div>
                </div>
            </div>
        </div>

    </div>
   
</template>

<script>
    import * as api from '@/api/api';
    import Vue from 'vue';
    import Aside from "@/components/common/aside.vue";
     Vue.filter('fil1',function(item){//item就是上方的状态码
		if(item==0){
			return '审核中'
		}
		if(item==1){
			return "招标中"
		}
		
		if(item==2){
			return '审核未通过'	
        }
        if(item==3){
			return '已完成'	
        }
        if(item==4){
			return '已关闭'	
        }
        
	})
    export default {
        name: "index",
        components: { Aside},//注册组件
        data() {
            return {
                radio1: '上海',
                radio2: '上海',
                num1: 5,
                show:true,
                shows:false,
                info:"20",
                activeName:"",
                info1:"全部购买订单",
                flagSelect:false,
                info2:"需求状态",

                 
                currentPage: 1, //默认展示去往第一页·
                
                list:[1],
                page:1,
                rows:8,
                state:10, //默认显示所有注册状态
                totalSize:0, //一共几条数据
                totalPage:""
            }
        },
        methods:{
            showCont(){
                this.show==true,
                this.shows=false
            },
            showConts(){
                this.show==false,
                this.shows=true
            },
            handleClick(){

            },
            btn1(){
                this.flagSelect=true
            },
            handleCommand(command) {//点击下拉列表触发事件
                this.info2=command;
                if(command=="审核中"){
                    this.state=0;
                }
                if(command=="招标中"){
                    this.state=1;
                }
                if(command=="审核未通过"){
                    this.state=2;
                }
                if(command=="已完成"){
                    this.state=3;
                }
                if(command=="已关闭"){
                    this.state=4;
                }
               if(command=="全部状态"){
                    this.state=10;
                }
            
                let self=this;
                let searchParams = {page:self.page, rows:self.rows,state:self.state}
                api.myneeds(searchParams).then(res => {
                self.list=res.data.data.resData;
                self.totalSize=res.data.data.totalSize; //共几条数据
                });
            },
            handleSizeChange(val) {//分页
                this.rows=`${val}`; //每页多少条
                let self = this;
                let searchParams = {page:self.page, rows:self.rows,state:self.state}
                api.myneeds(searchParams).then(res => {//上方数据传进myneeds这个api内的params
                    self.list=res.data.data.resData;
                });
            },
            handleCurrentChange(val) {
                    this.page=`${val}`; //一共几页
                    let self = this;
                    let searchParams = {page:self.page, rows:self.rows,state:self.state}
                    api.myneeds(searchParams).then(res => {
                    self.list=res.data.data.resData;
                });
            },    
            myneeds() {//默认展示的数据
                let self = this;
                let searchParams = {page:self.page, rows:self.rows,state:self.state}
                api.myneeds(searchParams).then(res => {
                self.list=res.data.data.resData;
                self.totalSize=res.data.data.totalSize; //共几条数据
                });
             }
        },
        mounted() {
            this.myneeds()
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../assets/scss/common";
      
    *{
        margin:0;
        padding:0;
    }
    li{
        list-style: none;
    }
    a{
        text-decoration: none;
    }
    .main{
        background: #fff !important;
    }
    .main-r{
        padding:0 20px;
        .div1{
            height: 50px;
            display: flex;
            line-height: 50px;
            color: #333;
            font-size: 18px;
            padding-left: 20px;
        }
        .div2{
            height: 50px;
            border:1px solid #e5e5e5;
            background: #f5f5f5;
            display: flex;
            align-items: center;
            >div{
                display: flex;
                justify-content: center;
                margin:0 20px;
            }
            .div2-1{
                width: 140px; 
            }
            .div2-2{
                width: 170px; 
            }
            .div2-3{
                width: 120px; 
            }
            .div2-4{
                width: 100px; 
            }
            .div2-5{
                width: 100px; 
            }
            .status{
                width:90px;
            }
        }

        // 以下是需求列表
        .ulList{
            font-size: 15px;
            li{
                height: 72px;
                display: flex;
                border: 1px solid #e5e5e5;
                border-top: none;
                flex-shrink:0;
                >div{
                    text-align: center;
                    line-height: 72px;
                    margin:0 20px;
                }
                 .li1{
                    width: 140px; 
                }
                .li2{
                    width: 170px; 
                    overflow:hidden;
                    text-overflow:ellipsis;
                    white-space:nowrap;
                    a{
                        color:#333;
                    }
                    a:hover{
                        color:#d82229;
                    }  
                }
                .li3{
                    width: 120px; 
                    overflow:hidden;
                    text-overflow:ellipsis;
                    white-space:nowrap;
                }
                .li4{
                    width: 100px; 
                    display: flex;
                    align-items: center;
                    >div{
                        line-height: 30px;
                    }
                }
                .li5{
                     width: 100px; 
                    display: flex;
                    align-items: center;
                    >div{
                        line-height: 30px;
                    }
                }
                .li6{
                    width:90px; 
                }
                }
        }
    }
    /deep/ .el-dropdown-menu__item{
        text-align: center;
    }
    /deep/ .el-dropdown-link{
        cursor: pointer;
    }
    /deep/ .el-dropdown-link{
        font-size: 15px;
        color: #2c3e50;
    }

    .pagination{
        width: 100%;
        display: flex;
        justify-content: center;
        margin:60px 0 90px 0;
    }


   
</style>
